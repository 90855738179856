<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="brands"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiCarBrakeAbs }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    color="accent"
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    rounded
                    @click.prevent="addnewBrandModal"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-show="currentUser.role_id == 1">
              <v-btn color="accent" rounded @click.prevent="addnewBrandModal">
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New</span>
              </v-btn>
            </div>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(brand, index1) in items" :key="index1">
            <td>{{ brand.name }}</td>
            <td>{{ formatDate(brand.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="user_submenu.update == 1 && permission.role_id == 3"
                            color="primary"
                            @click.prevent=""
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            dark
                            class="mx-1"
                          >
                            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click.prevent=""
                            v-show="user_submenu.delete == 1 && permission.role_id == 3"
                            color="error"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            dark
                          >
                            <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="currentUser.role_id == 1"
                        color="primary"
                        @click.prevent=""
                        fab
                        x-small
                        dark
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.prevent=""
                        v-show="currentUser.role_id == 1"
                        color="error"
                        fab
                        x-small
                        dark
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getBrands"> Reset </v-btn>
      </template>
    </v-data-table>

    <addnew-brand
      :dialog.sync="showAddNewBrandDialog"
      @open-dialog="showAddNewBrandDialog = true"
      @close-dialog="showAddNewBrandDialog = false"
    />

    <!-- <edit-rate
      :dialog.sync="showEditRateDialog"
      :rate="rate"
      @open-dialog="showEditRateDialog = true"
      @close-dialog="showEditRateDialog = false"
    /> -->
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiPercentOutline,
  mdiDeleteForeverOutline,
  mdiCarBrakeAbs,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AddNewBrandDialog from '@/views/settings/brand/AddNewBrand.vue'
import EditBrandDialog from '@/views/settings/brand/EditBrand.vue'

export default {
  components: {
    'edit-brand': EditBrandDialog,
    'addnew-brand': AddNewBrandDialog,
  },

  created: function() {
    this.getBrands()
    this.getAuthorizations()
    window.mitt.on('loadBrands', () => {
      this.getBrands()
      this.getAuthorizations()
    })
  },

  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      brands: [],
      brand: {},
      permission: {},
      search: '',
      showAddNewBrandDialog: false,
      showEditBrandDialog: false,

      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPercentOutline,
        mdiCarBrakeAbs,
      },
    }
  },
  methods: {
    getBrands: function() {
      this.$store
        .dispatch('brand/fetchBrands')
        .then(response => {
          this.brands = this.fetchBrands
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 4,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteBrand(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`brand/deleteBrand`, {
                brand_id: id,
              })
              .then(response => {
                this.getBrands()
              })
          }
        })
    },
    addnewBrandModal: function() {
      this.showAddNewBrandDialog = true
    },

    editRateModal: function(rate) {
      this.rate = Object.assign({}, rate)
      this.showEditRateDialog = true
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchRates: 'brand/fetchBrands',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),

    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style></style>
